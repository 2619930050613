import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "Components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "Components/misc/Buttons.js";

const Container = tw.div`relative px-4 md:px-8 lg:px-16`; // Added horizontal padding for better spacing on smaller screens

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12 md:py-16 lg:py-20`; // Adjusted padding for better responsiveness

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-12 md:mt-0`, // Adjusted margin for smaller screens
  props.textOnLeft ? tw`md:mr-8 lg:mr-12 md:order-first` : tw`md:ml-8 lg:ml-12 md:order-last`
]);

const Image = styled.div`
  ${tw`rounded bg-contain bg-no-repeat bg-center h-full`}
  background-image: url("/path-to-your-image.jpg");
`;

const TextContent = tw.div`lg:py-8`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-sm md:text-base lg:text-lg`; // Adjusted font size for better readability

const Heading = tw(SectionHeading)`mt-4 font-black text-left text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center md:text-left leading-tight`; // Adjusted font size for responsiveness

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`;

export default function TwoColWithButton({
  subheading = "OUR MISSION",
  heading = (
    <>
      Transforming Ideas Into Exceptional Experiences
    </>
  ),
  description = "At MilaajBrandset, we deliver exceptional digital solutions tailored to your needs. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  primaryButtonText = "Contact Us",
  primaryButtonUrl = "https://milaajbrandset.com",
  imageSrc = "/path-to-your-image.jpg",
  textOnLeft = false
}) {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image css={tw`bg-cover h-full rounded`} style={{ backgroundImage: `url(${imageSrc})` }} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
}
