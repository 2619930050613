import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from 'Components/headers/light';
import Features from "Components/Features/ThreeColSimple";
import TeamCardGrid from "Components/Cards/ProfileThreeColGrid";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About MilaajBrandset</Subheading>}
        heading="Empowering Your Digital Presence"
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        description="At MilaajBrandset, we specialize in enhancing your digital presence through cutting-edge web design and development. Our team of experts is dedicated to creating innovative solutions that not only meet but exceed your expectations. We believe in the power of a strong online presence and work tirelessly to ensure your brand stands out in the digital landscape."
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Transforming Ideas into Exceptional Experiences"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        description="Our vision is to transform your ideas into exceptional digital experiences. We aim to create visually stunning, user-friendly websites that engage and captivate your audience. Our commitment to excellence drives us to continuously innovate and push the boundaries of what is possible in web development."
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="Guiding Principles That Define Us"
        description="At MilaajBrandset, we are committed to delivering innovative solutions that drive your business forward."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Our dedicated support team ensures your queries are addressed promptly and efficiently."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "We build strong teams of experts who collaborate to deliver outstanding results."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Customer satisfaction is at the heart of everything we do."
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Meet Our Team</Subheading>}
      /> */}
    </AnimationRevealPage>
  );
};
