import React, { useState } from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { ChevronDown } from "lucide-react";

import HeaderWithDropdown, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(HeaderWithDropdown)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw(Link)`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline inline-block`;

const DropdownContainer = styled.div`
  ${tw`relative inline-block`}
`;

const DropdownButton = styled(NavLink)`
  ${tw`inline-flex items-center cursor-pointer`}
`;

const DropdownMenu = styled.div`
  ${tw`absolute left-0 w-56 mt-2 origin-top-left bg-gray-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
`;

const DropdownItem = styled(Link)`
  ${tw`block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white`}
`;

const ServicesDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const services = [
    { title: "Digital Marketing", path: "/digital-marketing" },
    { title: "API Development", path: "/api-development" },
    { title: "Web Development", path: "/web-development" },
    { title: "E-Commerce", path: "/e-commerce" },
    { title: "UI/UX Design", path: "/ui-ux-design" },
  ];

  return (
    <DropdownContainer
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <DropdownButton as="div" onClick={() => setIsOpen(!isOpen)}>
        Services <ChevronDown aria-hidden="true" />
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {services.map((service, index) => (
            <DropdownItem key={index} to={service.path}>
              {service.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink to="/about">About Us</NavLink>
      {/* <NavLink to="/blogs">Blog</NavLink> */}
      <NavLink to="/contact">Contact Us</NavLink>
      <ServicesDropdown />
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink to="/#">Hire Us</PrimaryLink>
    </NavLinks>,
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <Notification>Now serving clients worldwide!</Notification>
            <Heading>
              <span>Elevate Your Business</span>
              <br />
              <SlantedBackground>with Our Web Services.</SlantedBackground>
            </Heading>
            <PrimaryAction to="/">Discover Our Solutions</PrimaryAction>
          </LeftColumn>
          <RightColumn>{/* Placeholder for video embed */}</RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
