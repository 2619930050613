import React from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import Header from 'Components/headers/light'
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "Components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "Components/misc/Headings.js";
import { SectionDescription } from "Components/misc/Typography.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

// Import SVG icons
import { ReactComponent as DigitalMarketingIcon } from "images/digital-marketing-icon.svg";
import { ReactComponent as ApiDevelopmentIcon } from "images/api-development-icon.svg";
import { ReactComponent as WebDevelopmentIcon } from "images/web-development-icon.svg";
import { ReactComponent as ECommerceIcon } from "images/e-commerce-icon.svg";
import { ReactComponent as UiUxDesignIcon } from "images/ui_ux-design-icon.svg";

const HeadingContainer = tw.div`text-center`;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`;
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`;
const CardImage = styled.div`
  ${tw`w-64 h-64 flex items-center justify-center bg-gray-200 rounded`}
  svg {
    ${tw`w-32 h-32 text-primary-500`}
  }
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .title {
    ${tw`text-xl font-bold text-center`}
  }
  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose text-center`}
  }
`;

const LearnMoreLink = styled(Link)`
  ${tw`inline-block mt-4 text-sm font-bold text-primary-500 transition duration-300`}
  &:hover {
    ${tw`text-primary-700`}
  }
`;

const services = [
  { title: "Digital Marketing", path: "/digital-marketing", description: "Boost your online presence and reach your target audience effectively.", icon: DigitalMarketingIcon },
  { title: "API Development", path: "/api-development", description: "Create robust and scalable APIs to power your applications and integrate systems.", icon: ApiDevelopmentIcon },
  { title: "Web Development", path: "/web-development", description: "Build responsive and user-friendly websites tailored to your business needs.", icon: WebDevelopmentIcon },
  { title: "E-Commerce", path: "/e-commerce", description: "Develop powerful online stores to grow your business in the digital marketplace.", icon: ECommerceIcon },
  { title: "UI_UX Design", path: "/ui-ux-design", description: "Design intuitive and engaging user interfaces for exceptional user experiences.", icon: UiUxDesignIcon },
];

export default () => {
  return (
    <Container>
        <Header />
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Subheading>Our Expertise</Subheading>
          <Heading>Comprehensive Digital Solutions</Heading>
          <Description>
            MilaajBrandset offers a wide range of services to help your business thrive in the digital world. 
            From marketing to development, we've got you covered.
          </Description>
        </HeadingContainer>
        <Cards>
          {services.map((service, index) => (
            <Card key={index}>
              <CardImage>
                {React.createElement(service.icon, { title: service.title })}
              </CardImage>
              <CardContent>
                <span className="title">{service.title}</span>
                <p className="description">{service.description}</p>
                <LearnMoreLink to={service.path}>
                  Learn More <ArrowRightIcon tw="w-4 h-4 ml-2" />
                </LearnMoreLink>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};