import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "Components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "Components/headers/light.js";
import { SectionHeading } from "Components/misc/Headings";
import { PrimaryButton } from "Components/misc/Buttons";
import { Helmet } from "react-helmet";

const HeadingRow = tw.div`flex justify-center`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = styled.div`
  ${tw`mt-6 flex flex-wrap -m-4`}
`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 p-6`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Latest Blog Posts",
  posts = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      category: "Web Development",
      date: "July 22, 2024",
      title: "Top Web Development Trends in 2024",
      description:
        "Stay updated with the latest trends in web development. Learn about new technologies, frameworks, and best practices that are shaping the web in 2024.",
      url: "/blog/top-web-development-trends-2024",
      featured: true
    },
    {
      imageSrc:
        "https://plus.unsplash.com/premium_photo-1685283298465-e52e933a3312?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      category: "SEO",
      date: "July 19, 2024",
      title: "SEO Strategies for 2024: Boost Your Rankings",
      description:
        "Learn the most effective SEO strategies for 2024. Boost your website's visibility and rankings with these tips and techniques.",
      url: "/blog/seo-strategies-2024",
      featured: false
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      category: "Digital Marketing",
      date: "July 15, 2024",
      title: "Effective Digital Marketing Campaigns",
      description:
        "Discover how to create and manage effective digital marketing campaigns. Learn about the latest tools and techniques to maximize your ROI.",
      url: "/blog/effective-digital-marketing-campaigns",
      featured: false
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      category: "E-commerce",
      date: "July 10, 2024",
      title: "E-commerce Trends to Watch in 2024",
      description:
        "Stay ahead of the curve with the latest e-commerce trends. Learn how to optimize your online store for better sales and customer experience.",
      url: "/blog/ecommerce-trends-2024",
      featured: false
    }
  ]
}) => {
  const [visible, setVisible] = useState(10);

  const onLoadMoreClick = () => {
    setVisible(v => v + 3);
  };

  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Blog | MilaajBrandset</title>
        <meta name="description" content="Read our latest blog posts on web development, SEO, digital marketing, e-commerce, and more. Stay updated with industry trends and insights from MilaajBrandset." />
        <meta name="keywords" content="MilaajBrandset, blog, web development, SEO, digital marketing, e-commerce, industry trends" />
      </Helmet>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
