import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from 'Components/headers/light'
import Features from "Components/Features/ThreeColSimple";

import ApiIconImage from "images/api-icon.svg";
import SecurityIconImage from "images/security-icon.svg";
import SupportIconImage from "images/support-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>API Development Experts</Subheading>}
        heading="Powerful APIs for Your Business"
        buttonRounded={false}
        primaryButtonText="Get Started"
        imageSrc="https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        description="Unlock the full potential of your business with our robust API development services. At MilaajBrandset, we design and develop powerful APIs that facilitate seamless integration and automation, ensuring your systems communicate efficiently and effectively."
      />
      <MainFeature1
        subheading={<Subheading>Our Approach</Subheading>}
        heading="Customized API Solutions"
        buttonRounded={false}
        primaryButtonText="Learn More"
        imageSrc="https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        description="Our approach to API development is centered on customization and precision. We take the time to understand your unique business needs and create APIs that are tailored to meet those needs. Whether you're looking to enhance your current systems or develop new integrations, our team of experts is here to deliver top-notch solutions."
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive API Development"
        description="MilaajBrandset offers end-to-end API development services to streamline your business processes and enhance connectivity. From initial consultation to deployment and maintenance, we ensure that your APIs are robust, secure, and scalable."
        cards={[
          {
            imageSrc: ApiIconImage,
            title: "Custom API Development",
            description: "We create tailored APIs that perfectly align with your business needs and integrate seamlessly with your existing systems. Our custom APIs are designed to enhance functionality and improve operational efficiency."
          },
          {
            imageSrc: SecurityIconImage,
            title: "API Security",
            description: "Our APIs are built with robust security measures to protect your data and ensure safe transactions. We implement the latest security protocols to safeguard your information against potential threats and breaches."
          },
          {
            imageSrc: SupportIconImage,
            title: "API Maintenance & Support",
            description: "We provide ongoing maintenance and support to keep your APIs running smoothly and up-to-date with the latest standards. Our team is always available to troubleshoot issues and make necessary updates, ensuring optimal performance."
          },
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};
