import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "Components/headers/light.js";
import Footer from "Components/Footer/MiniCenteredFooter";
import ContactUsForm from "Components/Forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "Components/Cards/ThreeColContactDetails.js";

// Updated address and contact information
const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

const emailLink = "mailto:support@milaajbrandset.com";
const phoneNumber = "+971 0505225056";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Dubai",
            description: (
              <>
                <Address>
                  <AddressLine>The Iridium Building, G-floor</AddressLine>
                  <AddressLine>Behind Mall of Emirates, Al Barsha 1</AddressLine>
                  <AddressLine>Dubai</AddressLine>
                </Address>
                <Email><a href={emailLink} target="_blank" rel="noopener noreferrer">support@milaajbrandset.com</a></Email>
                <Phone><a href={`tel:${phoneNumber.replace(/ /g, '')}`}>{phoneNumber}</a></Phone>
              </>
            )
          },
        ]}
      />
    </AnimationRevealPage>
  );
};
