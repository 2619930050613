import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/logo.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";

const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 w-full sm:w-1/2 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const ServicesLinkList = styled.ul`
  ${tw`mt-6 text-sm font-medium`}
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 1rem; /* Space between items */
`;

const LinkList = styled.ul`
  ${tw`mt-6 text-sm font-medium`}
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const LinkListItem = styled.li`
  ${tw`relative`}
  &:not(:last-child)::after {
    content: "";
    ${tw`absolute bottom-0 left-0 w-full border-b border-gray-300`}
    transform: translateY(10px);
  }
`;

const Link = styled.a`
  ${tw`block pb-1 text-gray-200 transition duration-300 hover:text-gray-100 hover:border-gray-100`}
  border-bottom: 2px solid transparent;
  text-decoration: none;
  &:hover {
    ${tw`border-gray-100`}
  }
`;

const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;

export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
            <ColumnHeading>Main</ColumnHeading>
            <LinkList>
              {/* <LinkListItem>
                <Link href="/blogs">Blog</Link>
              </LinkListItem> */}
              <LinkListItem>
                <Link href="/faq">Faq</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="contact">Support</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="about">About Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Services</ColumnHeading>
            <ServicesLinkList>
              <LinkListItem>
                <Link href="digital-marketing">Digital Marketing</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="api-development">API Development</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="web-development">Web Development</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="e-commerce">E-Commerce</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="ui-ux-design">UI_UX Design</Link>
              </LinkListItem>
            </ServicesLinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Disclaimer</Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>MilaajBrandset.</LogoText>
          </LogoContainer>
          <CopywrightNotice>&copy; 2024 MilaajBrandset. All Rights Reserved.</CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
