import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from 'Components/headers/light';
import Features from "Components/Features/ThreeColSimple";

import EcommerceIconImage from "images/ecommerce-icon.svg";
import PaymentGatewayIconImage from "images/payment-gateway-icon.svg";
import InventoryIconImage from "images/inventory-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>E-commerce Solutions</Subheading>}
        heading="Elevate Your Online Store with MilaajBrandset"
        buttonRounded={false}
        primaryButtonText="Start Selling Online"
        imageSrc="https://images.unsplash.com/photo-1607082348824-0a96f2a4b9da?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        description="Transform your business with our comprehensive e-commerce solutions. At MilaajBrandset, we specialize in developing custom online stores that not only look great but also provide a seamless shopping experience for your customers."
      />
      <MainFeature1
        subheading={<Subheading>Our Approach</Subheading>}
        heading="Customer-Centric E-commerce Development"
        buttonRounded={false}
        primaryButtonText="Learn More"
        imageSrc="https://images.unsplash.com/photo-1556742049-0cfed4f6a45d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        description="Our approach to e-commerce development is centered around the customer. We focus on creating intuitive, engaging, and secure online stores that drive sales and enhance customer satisfaction. With MilaajBrandset, you can be assured of a user-friendly and high-performing e-commerce platform."
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive E-commerce Solutions"
        description="MilaajBrandset offers end-to-end e-commerce development services to create powerful, scalable, and user-friendly online stores that drive your business growth. Our services are designed to cover every aspect of your e-commerce needs."
        cards={[
          {
            imageSrc: EcommerceIconImage,
            title: "Custom E-commerce Development",
            description: "We build tailor-made e-commerce platforms that align perfectly with your brand and business needs. Our custom solutions are designed to provide a unique shopping experience that sets you apart from the competition."
          },
          {
            imageSrc: PaymentGatewayIconImage,
            title: "Secure Payment Integration",
            description: "We integrate robust and secure payment gateways to ensure safe and smooth transactions for your customers. Our solutions support multiple payment methods, giving your customers flexibility and peace of mind."
          },
          {
            imageSrc: InventoryIconImage,
            title: "Inventory Management",
            description: "Our e-commerce solutions come with powerful inventory management tools to streamline your operations. Easily track stock levels, manage orders, and ensure your inventory is always up-to-date."
          },
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};
