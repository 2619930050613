import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from 'Components/headers/light';
import Features from "Components/Features/ThreeColSimple";
import TeamCardGrid from "Components/Cards/ProfileThreeColGrid";

import SeoIconImage from "images/seo-icon.svg";
import ContentIconImage from "images/content-icon.svg";
import AnalyticsIconImage from "images/analytics-icon.svg";
import DigitalMarketing from "images/digital-marketing-1.avif"

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Digital Marketing Experts</Subheading>}
        heading={<Heading>Boost Your Online Presence with MilaajBrandset</Heading>}
        buttonRounded={false}
        primaryButtonText="Get a Free SEO Audit"
        imageSrc="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        description={
          <Description>
            At MilaajBrandset, we specialize in enhancing your digital footprint. Our team of experts utilizes cutting-edge SEO techniques to ensure your brand stands out in the crowded digital landscape. With our comprehensive digital marketing services, we help you attract, engage, and convert more leads.
          </Description>
        }
      />
      <MainFeature1
        subheading={<Subheading>Our Approach</Subheading>}
        heading={<Heading>Data-Driven Digital Marketing Strategies</Heading>}
        buttonRounded={false}
        primaryButtonText="Learn More"
        imageSrc="https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        description={
          <Description>
            Our approach is rooted in data and driven by results. We leverage the power of analytics to craft personalized marketing strategies that align with your business goals. From SEO and content marketing to detailed analytics and reporting, we cover all aspects to ensure your digital success.
          </Description>
        }
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Services</Subheading>}
        heading={<Heading>Comprehensive Digital Marketing Solutions</Heading>}
        description={
          <Description>
            MilaajBrandset offers a full suite of digital marketing services to elevate your brand's online presence and drive measurable results. Our services are designed to cater to all your digital marketing needs, ensuring a cohesive and effective strategy.
          </Description>
        }
        cards={[
          {
            imageSrc: SeoIconImage,
            title: "Search Engine Optimization",
            description: "Improve your website's visibility in search results with our expert SEO strategies. We focus on both on-page and off-page SEO to boost your rankings and drive organic traffic."
          },
          {
            imageSrc: ContentIconImage,
            title: "Content Marketing",
            description: "Engage your audience with compelling, SEO-optimized content that drives conversions. Our content marketing services include blog posts, articles, infographics, and more."
          },
          {
            imageSrc: AnalyticsIconImage,
            title: "Analytics & Reporting",
            description: "Make data-driven decisions with our comprehensive analytics and reporting services. We provide detailed insights into your digital marketing performance, helping you optimize your strategies for better results."
          },
        ]}
        linkText=""
      />
      {/* Uncomment the following lines to include the team section */}
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Digital Marketing Experts</Subheading>}
        heading={<Heading>Meet the Team</Heading>}
        description={
          <Description>
            Our team of digital marketing experts is dedicated to your success. With years of experience and a passion for all things digital, we are here to help your business grow.
          </Description>
        }
      /> */}
    </AnimationRevealPage>
  );
};
