import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from './Components/Hero/Hero.js';
import Features from "./Components/Features/DashedBorderSixFeatures.js";
import MainFeature from "./Components/Features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "./Components/Features/TwoColWithTwoFeaturesAndButtons.js";
import FAQ from "./Components/Faqs/SingleCol.js";
import ContactUsForm from "./Components/Forms/TwoColContactUsWithIllustration.js";
import ContactUs from "Pages/ContactUs.js";
import Footer from "./Components/Footer/MiniCenteredFooter.js";
import Aboutus from "Pages/Aboutus.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import Blog from './Components/Blogs/ThreeColSimpleWithImageAndDashedBorder.js'
import BlogIndex from "Pages/BlogIndex.js";
import DigitalMarketing from "Pages/DigitalMarketing.js";
import ApiDevelopment from "Pages/ApiDevelopment.js";
import WebDevelopment from "Pages/WebDevelopment.js";
import E_Commerce from "Pages/E_Commerce.js";
import Services from "Pages/Services.js";
import Faq from "Pages/Faq.js";

const HomePage = () => (
  <>
    <Helmet>
      <title>Home | MilaajBrandset</title>
      <meta name="description" content="Welcome to MilaajBrandset. We provide top-notch digital solutions including web development, digital marketing, and more to help your business succeed online." />
      <meta name="keywords" content="MilaajBrandset, digital solutions, web development, digital marketing, SEO services, online business solutions" />
      <meta property="og:title" content="Home | MilaajBrandset" />
      <meta property="og:description" content="Welcome to MilaajBrandset. We provide top-notch digital solutions including web development, digital marketing, and more to help your business succeed online." />
    </Helmet>
    <Hero />
    <MainFeature />
    <Features />
    <MainFeature2 />
    {/* <Blog /> */}
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions?</span>
        </>
      }
    />
    <ContactUsForm />
  </>
);

const AboutPage = () => (
  <>
    <Helmet>
      <title>About Us | MilaajBrandset</title>
      <meta name="description" content="Learn more about MilaajBrandset, our values, and our mission to deliver exceptional digital solutions tailored to your business needs." />
      <meta name="keywords" content="MilaajBrandset, about us, company information, digital solutions, business values" />
      <meta property="og:title" content="About Us | MilaajBrandset" />
      <meta property="og:description" content="Learn more about MilaajBrandset, our values, and our mission to deliver exceptional digital solutions tailored to your business needs." />
    </Helmet>
    <Aboutus />
  </>
);

const ContactPage = () => (
  <>
    <Helmet>
      <title>Contact Us | MilaajBrandset</title>
      <meta name="description" content="Get in touch with MilaajBrandset. Whether you have questions about our services or need support, we're here to help you." />
      <meta name="keywords" content="MilaajBrandset, contact us, customer support, digital solutions, business inquiries" />
      <meta property="og:title" content="Contact Us | MilaajBrandset" />
      <meta property="og:description" content="Get in touch with MilaajBrandset. Whether you have questions about our services or need support, we're here to help you." />
    </Helmet>
    <ContactUs />
  </>
);

const ServicesPage = () => (
  <>
    <Helmet>
      <title>Services | MilaajBrandset</title>
      <meta name="description" content="Explore the wide range of digital services offered by MilaajBrandset, including web development, SEO, and more to grow your business." />
      <meta name="keywords" content="MilaajBrandset, services, digital marketing, web development, SEO, business growth" />
      <meta property="og:title" content="Services | MilaajBrandset" />
      <meta property="og:description" content="Explore the wide range of digital services offered by MilaajBrandset, including web development, SEO, and more to grow your business." />
    </Helmet>
    <Services />
  </>
);

const BlogPage = () => (
  <>
    <Helmet>
      <title>Blog | MilaajBrandset</title>
      <meta name="description" content="Read our latest blog posts on digital marketing, web development, and other topics related to enhancing your business online presence." />
      <meta name="keywords" content="MilaajBrandset, blog, digital marketing, web development, business insights, industry news" />
      <meta property="og:title" content="Blog | MilaajBrandset" />
      <meta property="og:description" content="Read our latest blog posts on digital marketing, web development, and other topics related to enhancing your business online presence." />
    </Helmet>
    <BlogIndex />
  </>
);

const DigitalMarketingPage = () => (
  <>
    <Helmet>
      <title>Digital Marketing | MilaajBrandset</title>
      <meta name="description" content="Discover MilaajBrandset's digital marketing services designed to enhance your online presence and drive business growth." />
      <meta name="keywords" content="MilaajBrandset, digital marketing, SEO, online advertising, business growth" />
      <meta property="og:title" content="Digital Marketing | MilaajBrandset" />
      <meta property="og:description" content="Discover MilaajBrandset's digital marketing services designed to enhance your online presence and drive business growth." />
    </Helmet>
    <DigitalMarketing />
  </>
);

const ApiDevelopmentPage = () => (
  <>
    <Helmet>
      <title>API Development | MilaajBrandset</title>
      <meta name="description" content="Explore our API development services that provide custom solutions to integrate and enhance your business applications." />
      <meta name="keywords" content="MilaajBrandset, API development, custom APIs, business integration, technology solutions" />
      <meta property="og:title" content="API Development | MilaajBrandset" />
      <meta property="og:description" content="Explore our API development services that provide custom solutions to integrate and enhance your business applications." />
    </Helmet>
    <ApiDevelopment />
  </>
);

const WebDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Web Development | MilaajBrandset</title>
      <meta name="description" content="Our web development services offer cutting-edge solutions to build and optimize your website for better performance and user experience." />
      <meta name="keywords" content="MilaajBrandset, web development, website design, user experience, digital solutions" />
      <meta property="og:title" content="Web Development | MilaajBrandset" />
      <meta property="og:description" content="Our web development services offer cutting-edge solutions to build and optimize your website for better performance and user experience." />
    </Helmet>
    <WebDevelopment />
  </>
);

const ECommercePage = () => (
  <>
    <Helmet>
      <title>E-Commerce | MilaajBrandset</title>
      <meta name="description" content="Boost your online sales with MilaajBrandset's e-commerce solutions designed to enhance your store's performance and customer experience." />
      <meta name="keywords" content="MilaajBrandset, e-commerce solutions, online sales, store optimization, digital retail" />
      <meta property="og:title" content="E-Commerce | MilaajBrandset" />
      <meta property="og:description" content="Boost your online sales with MilaajBrandset's e-commerce solutions designed to enhance your store's performance and customer experience." />
    </Helmet>
    <E_Commerce />
  </>
);

export default () => (
  <Router>
    <AnimationRevealPage>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/faq" element={<Faq />}  />
        <Route path="/digital-marketing" element={<DigitalMarketingPage />} />
        <Route path="/api-development" element={<ApiDevelopmentPage />} />
        <Route path="/web-development" element={<WebDevelopmentPage />} />
        <Route path="/e-commerce" element={<ECommercePage />} />
      </Routes>
      <Footer  />
    </AnimationRevealPage>
  </Router>
);
